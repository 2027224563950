
button {
  cursor: pointer;
}

img {
  max-width: 100%;
}

.outer-container {
  max-width: 800px;
  margin: auto;
  display: flex; 
  border-radius: 5px; 
  overflow: hidden;
}

.outer-container > div {
  padding: 20px;
}

.outer-container .carousel-wrapper {
  display: flex;
  flex-direction: column;
}

.outer-container .carousel-wrapper p,
.carousel-item {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.3;
}

.outer-container .content {
  background: var(--white);
}

.leading-text {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 6px;
}

.carousel {
  height: 100%;
  display: flex;
  flex: 5;
  align-items: center;
  color: var(--white);
}

.carousel-button {
  display: flex;
  align-self: center;
  padding: 10px;
  max-width: 50px;
  border-radius: 30px;
  background: var(--orange);
  border: 2px solid var(--white);
}

.carousel-button svg {
  height: 26px;
  width: 26px;
}
.carousel-button path {
  fill: var(--white);
}

.slides {
  align-self: flex-start;
  flex: 1;
  width: 100%;
  overflow: hidden;
}

.carousel-inner {
  position: relative;
  max-height: 250px;
  height: 250px;
}

.carousel-inner::before,
.carousel-inner::after {
  content: '';
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 110px;
  pointer-events: none;
}

.carousel-inner::before {
  top: 0;
}

.carousel-inner::after {
  bottom: 0; 
}

.carousel-item {
  position: absolute;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  opacity: 0;
  top: 112px;
  transition: transform 0.4s ease, opacity 0.4s ease;
}

.carousel-item.visible {
  opacity: 1;
}

/* -------------------------------------------------------  */
/* html {
  box-sizing: border-box;
  font-size: 10px;
}
@media (max-width: 425px) {
  html {
    font-size: 8px;
 }
}
body {
  font-size: 1.5rem;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}
*, *:before, *:after {
  -webkit-box-sizing: border-box;
  box-sizing: inherit;
} */
ul {
  list-style-type: none;
  padding: 0;
}
/* a {
  text-decoration: none;
  color: inherit;
} */
.carousel__wrap {
  margin-top: 10%;
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  position: relative;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
}
.carousel__inner {
  height: 40rem;
  position: relative;
  width: calc(90rem);
}
.carousel__container {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.carousel__slide-list {
  height: 100%;
  left: 50%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  transform: translateX(-50%);
  width: calc(330rem);
}
.carousel__slide-item {
  display: inline-block;
  height: 30rem;
  margin: 0;
  padding: 1rem;
  position: absolute;
  transition: all 0.3s;
  width: 30rem;
}
.carousel__slide-item-img-link {
  cursor: zoom-in;
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.carousel__slide-item-img-link img {
  height: 100%;
  object-fit: cover;
  transition: all 0.5s ease;
  width: 100%;
}
.carousel__slide-item-img-link::after {
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  height: 100%;
  justify-content: center;
  opacity: 0;
  position: absolute;
  transition: all 0.5s ease;
  width: 100%;
}
.carousel__slide-item-img-link:hover::after {
  opacity: 1;
}

.carousel__slide-item-img-link-disable-hover:hover img {
  transform: scale(1) !important;
}

.carousel__slide-item-img-link:hover img {
  transform: scale(1.3);
}
.carousel-slide-item__body {
  bottom: -2.5rem;
  height: 10%;
  position: absolute;
}
.carousel-slide-item__body h4 {
  margin: 0.7rem 0 0;
  text-transform: uppercase;
}
.carousel-slide-item__body p {
  font-size: 1.2rem;
  line-height: 1.3;
  margin: 0.7rem 0 0;
}
.carousel__btn {
  align-items: center;
  background: 0;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.carousel__btn--prev {
  left: -10rem;
}
.carousel__btn--next {
  right: -10rem;
}
.carousel__btn-arrow {
  border: solid black;
  border-width: 0 0.4rem 0.4rem 0;
  height: 6rem;
  padding: 3px;
  width: 6rem;
  z-index: 10;
}
.carousel__btn-arrow--left {
  transform: rotate(135deg);
}
.carousel__btn-arrow--right {
  transform: rotate(-45deg);
}
.carousel__dots {
  display: inline-block;
  left: 50%;
  margin-top: 2rem;
  position: absolute;
  transform: translateX(-50%);
}
.carousel__dots .dot {
  background: #ccc;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  height: 2rem;
  margin: 0 0.3rem;
  outline: none;
  transform: scale(0.5);
  width: 2rem;
}
.carousel__dots .dot.active {
  background: black;
}

#search-input::placeholder {
  text-align: center;
}